import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Card = _resolveComponent("Card")!
  const _component_CheckboxImageInput = _resolveComponent("CheckboxImageInput")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_KycProduct = _resolveComponent("KycProduct")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_KycLayout = _resolveComponent("KycLayout")!

  return (_openBlock(), _createBlock(_component_KycLayout, {
    title: _ctx.t('Tell Us About Your Self'),
    class: "register-kyc-form"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        model: _ctx.formState,
        onFinish: _ctx.handleFinish,
        onFinishFailed: _ctx.handleFailed,
        loading: _ctx.updateBusinessProfileLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_space, {
            size: 24,
            direction: "vertical",
            class: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: _ctx.t(`What's your brand name?`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    name: "nickname",
                    value: _ctx.formState.nickname,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.nickname) = $event)),
                    placeholder: _ctx.t('Your nickname here'),
                    rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                  }, null, 8, ["value", "placeholder", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t('What industry are you in?')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CheckboxImageInput, {
                    name: "industry",
                    options: _ctx.industryCheckboxImageOptions,
                    value: _ctx.formState.industry,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.industry) = $event)),
                    span: 24,
                    direction: "horizontal",
                    "single-select": "",
                    rules: [{ validator: _ctx.industryValidator, trigger: 'change' }],
                    onChangeCapture: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleIndustryChange('industry')))
                  }, null, 8, ["options", "value", "rules"]),
                  _createVNode(_component_a_card, { class: "register-kyc-form__other-industry-card" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputText, {
                        label: _ctx.t('Other'),
                        name: "otherIndustry",
                        value: _ctx.formState.otherIndustry,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.otherIndustry) = $event)),
                        placeholder: _ctx.t('Please specify'),
                        rules: [{ validator: _ctx.industryValidator, trigger: 'change' }],
                        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleIndustryChange('otherIndustry')))
                      }, null, 8, ["label", "value", "placeholder", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_KycProduct, {
                "product-id": _ctx.formState.productId,
                "onUpdate:product-id": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.productId) = $event)),
                "product-description": _ctx.formState.productDescription,
                "onUpdate:product-description": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.productDescription) = $event)),
                "industry-id": _ctx.formState.industry[0],
                "onUpdate:industry-id": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.industry[0]) = $event))
              }, null, 8, ["product-id", "product-description", "industry-id"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              loading: _ctx.updateBusinessProfileLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Submit")), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["model", "onFinish", "onFinishFailed", "loading"])
    ]),
    _: 1
  }, 8, ["title"]))
}