
import CheckboxImageInput from "@/shared/components/Forms/CheckboxImageInput.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import Card from "@/shared/components/Card.vue";
import KycLayout from "@/shared/components/Layouts/KycLayout.vue";
import KycProduct from "@/shared/components/Kyc/Items/KycProduct.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { isEmpty } from "lodash";
import { computed, defineComponent, reactive, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { useFilterOptions } from "@/shared/composables/useFilterOptions";
import { NONE_OF_THE_ABOVE } from "@/shared/composables/useProducts";
import { UpdateBusinessProfileVariables } from "@/api/kyc/__generated__/UpdateBusinessProfile";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";

export interface RegisterKycFormState {
  nickname: string;
  industry: string[];
  otherIndustry: string;
  productId: string | null;
  productDescription: string;
}

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const {
      businessProfile,
      saveBusinessProfile,
      updateBusinessProfileLoading,
    } = useBusinessProfile();
    const {
      industryCheckboxImageOptions,
      filterOptions: { industries },
    } = useFilterOptions();
    const formState = reactive<RegisterKycFormState>({
      nickname: "",
      industry: [],
      otherIndustry: "",
      productId: "",
      productDescription: "",
    });

    // Parsed data for sending to API
    const parsedFormState = computed(
      (): UpdateBusinessProfileVariables => ({
        input: {
          basicInformation: {
            nickname: formState.nickname,
            industryId: Number(formState.industry[0]),
            industryOther: formState.otherIndustry,
          },
          productInformation: {
            productId:
              !formState.productId || formState.productId === NONE_OF_THE_ABOVE
                ? null
                : formState.productId,
            productDescription:
              !formState.productId || formState.productId === NONE_OF_THE_ABOVE
                ? formState.productDescription
                : "",
          },
        },
      })
    );

    const handleFinish = async () => {
      const success = await saveBusinessProfile(parsedFormState.value);
      if (success) {
        router.push({ name: routeNames.home });
      }
    };

    const handleFailed = (formState) => {
      console.log("RegisterKycForm handleFailed?", formState);
    };

    const handleIndustryChange = (
      changedItem: "industry" | "otherIndustry"
    ) => {
      if (changedItem == "industry") {
        formState.otherIndustry = "";
      } else {
        formState.industry = [];
      }

      resetProduct();
    };

    // Custom Form Validators
    const industryValidator = async () => {
      if (
        isEmpty(formState.industry) &&
        isEmpty(formState.otherIndustry.trim())
      ) {
        return Promise.reject(
          t("Select an industry from the options, or specify one.")
        );
      }

      return Promise.resolve();
    };

    watchEffect(() => {
      // Load values from API
      if (businessProfile.value) {
        // Values from API
        const nickname = businessProfile.value.nickname;
        const industry = businessProfile.value.industry;

        // Load Nickname
        formState.nickname = nickname ?? "";

        // If selected from industry options
        if (industry) {
          const selectedIndustryOption =
            industryCheckboxImageOptions.value.find(
              (option) => option.value === industry.id
            );

          // If industry is in options
          if (selectedIndustryOption) {
            formState.industry = [selectedIndustryOption.value as string];
          }
        }
        // Else, if specific option
        else {
          formState.otherIndustry = businessProfile.value.industryOther;
        }

        // Load Selected Product
        if (businessProfile.value.product) {
          formState.productId = businessProfile.value.product.id;
          formState.productDescription = "";
        }
        // Load Product Description
        else if (businessProfile.value.productDescription) {
          formState.productId = NONE_OF_THE_ABOVE;
          formState.productDescription =
            businessProfile.value.productDescription;
        }
      }
    });

    const resetProduct = () => {
      formState.productId = null;
      formState.productDescription = "";
    };

    return {
      t,
      formState,
      handleFinish,
      handleFailed,
      handleIndustryChange,
      makeRequiredRule,
      industryCheckboxImageOptions,
      industryValidator,
      industries,
      saveBusinessProfile,
      updateBusinessProfileLoading,
      resetProduct,
    };
  },
  emits: ["on-finish", "on-finish-failed"],
  components: {
    FormGroup,
    KycLayout,
    Card,
    KycProduct,
    InputText,
    CheckboxImageInput,
  },
});
